<template>
  <div
    class="w-11/12 md:w-10/12 flex flex-wrap items-start md:items-center justify-end md:justify-start font-proximaMedium"
  >
    <div
      class="w-44 block md:hidden mb-4 py-3 px-6 flex items-center justify-between bg-white rounded-md cursor-pointer"
    >
      <input class="w-10/12 md:w-8/12" type="text" placeholder="Postcode" />
      <LocationIcon
        class="ml-4 mt-1"
        iconColor="#0AC0CB"
        heigth="18"
        width="18"
      />
    </div>
    <div class="mb-4 w-full block md:hidden">
      <div class="relative">
        <input
          class="w-full py-3 px-4 rounded-md"
          type="text"
          placeholder="Search"
          v-model="searchWord"
          @keyup.enter="searchMethod"
        />
        <img
          class="h-4 absolute top-4 right-2"
          src="@/assets/images/search-icon.png"
          alt="search-icon"
        />
      </div>
    </div>
    <div
      class="w-44 md:w-3/12 lg:w-2/12 hidden md:flex mr-10 py-3 px-6 items-center justify-between bg-white rounded-md cursor-pointer"
    >
      <input class="w-10/12 md:w-8/12" type="text" placeholder="Postcode" />
      <LocationIcon
        class="ml-4 mt-1"
        iconColor="#0AC0CB"
        heigth="18"
        width="18"
      />
    </div>
    <div class="md:w-8/12 lg:w-8/12 hidden md:block">
      <div class="relative">
        <input
          class="w-full py-3 px-4 rounded-md"
          type="text"
          placeholder="Search"
          v-model="searchWord"
          @keyup.enter="searchMethod"
        />
        <img
          class="h-4 absolute top-4 right-2"
          src="@/assets/images/search-icon.png"
          alt="search-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeartIcon from "../icons/HeartIcon.vue";
import LocationIcon from "../icons/LocationIcon.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeartIcon,
    LocationIcon,
  },
  data() {
    return {
      form: {
        streetAddress: "",
        country: "",
        state: "",
        city: "",
        postalCode: "",
        emailAddress: "",
        phoneNo: "",
        website: "",
        serviceType: "",
        businessName: "",
        service_24_7: false,
        english_multilang: false,
        accept_credit_card: false,
        special_equipment: false,
        deferred_payment: false,
        loyalty_program: false,
        userEmailAddress: localStorage.getItem("email"),
      },

      searchWord: "",
      isFavorite: true,
      favoriteModals: false,
      suggestModals: false,
      countriesList: [],
      statesList: [],
      serviceTypesList: [],
      category: "",
      errors: {},
      favoriteData: [
        {
          address: "45 Chatswood , Autraili-03470000000",
          image: "pp-1",
          name: "Hundesalon Bad Belzig",
        },
        {
          address: "45 Chatswood , Autraili-03470000000",
          image: "pp-2",
          name: "Tierarztpraxis Moon",
        },
      ],
    };
  },
  computed: {
    favoriteComp() {
      if (this.isFavorite) {
        return "#ff0c00";
      } else {
        return "#fff";
      }
    },
  },
  methods: {
    ...mapActions({
      filterPetsaslost: "panicbutton/filterPetsaslost",
    }),
    searchMethod() {
      this.filterPetsaslost(this.searchWord);
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: absolute;
}
</style>
