<template>
  <div
    class="w-11/12 md:w-10/12 mt-4 flex items-start justify-between flex-wrap"
  >
    <div
      class="w-44 md:w-56 bg-primary-20 hover:bg-orange p-4 mt-4 font-proximaMedium cursor-pointer justify-center"
      v-for="(service, i) in data"
      :key="i"
      @click="routeTo(service)"
    >
      <!--  //"require('@/assets/images/' + service.image + '.png')" -->
      <img
        class="w-60 pb-2 rounded-xl"
        :src="service.petImage"
        :alt="service.image"
      />

      <span>{{ datee(service.lost_date) }}</span>
    </div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
  </div>
</template>

<script>
import moment from "moment";
import { infoReportedPetAsLost } from "../../services/panicButtonService";

export default {
  props: ["data"],
  data() {
    return {};
  },
  async created() {
    console.log("//////////////", this.data);
  },

  goTo(page, pet) {
    localStorage.setItem("petLost", JSON.stringify(pet));

    this.$router.push({
      name: page,
      params: { data: pet },
    });
  },
  computed: {},
  methods: {
    async routeTo(pet) {
      await infoReportedPetAsLost(pet.lost_id, pet.petID).then((result) => {
        console.log("............ : ", result.data);

        const petRecord = result.data;
        this.lostAreaModals = false;
        localStorage.setItem("petLost", JSON.stringify(petRecord));
        this.$router.push({
          name: "LostModeProfile",
          params: {
            data: petRecord,
          },
        });

        //this.goTo("LostModeProfile", petRecord);
      });

      /* this.$router.push({
        name: "LostModeProfile",
        params: {
          data: i,
        },
      }); */
    },
    datee(d) {
      return d ? moment(d.substr(0, 10)).format("MMMM DD, YYYY") : "";
    },
  },
};
</script>

<style></style>
