<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75 12C5.09625 11.64 0 8.82 0 3.75C0 2.96294 0.24764 2.19583 0.707842 1.55734C1.16804 0.918841 1.81748 0.441328 2.56415 0.192438C3.31082 -0.0564523 4.11687 -0.0641014 4.86813 0.170574C5.61939 0.40525 6.27776 0.870353 6.75 1.5C7.22224 0.870353 7.88061 0.40525 8.63187 0.170574C9.38313 -0.0641014 10.1892 -0.0564523 10.9359 0.192438C11.6825 0.441328 12.332 0.918841 12.7922 1.55734C13.2524 2.19583 13.5 2.96294 13.5 3.75C13.5 8.82 8.40375 11.64 6.75 12Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>