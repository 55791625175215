<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.625 0C2.51965 0 0 2.26793 0 5.0625C0 9.5625 5.625 15.75 5.625 15.75C5.625 15.75 11.25 9.5625 11.25 5.0625C11.25 2.26793 8.73035 0 5.625 0ZM5.625 7.875C5.17999 7.875 4.74498 7.74304 4.37497 7.49581C4.00496 7.24857 3.71657 6.89717 3.54627 6.48604C3.37597 6.0749 3.33142 5.6225 3.41823 5.18605C3.50505 4.74959 3.71934 4.34868 4.03401 4.03401C4.34868 3.71934 4.74959 3.50505 5.18605 3.41823C5.6225 3.33142 6.0749 3.37597 6.48604 3.54627C6.89717 3.71657 7.24857 4.00496 7.49581 4.37497C7.74304 4.74498 7.875 5.17999 7.875 5.625C7.87435 6.22154 7.63709 6.79346 7.21527 7.21527C6.79346 7.63709 6.22154 7.87435 5.625 7.875Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>