<template>
  <div class="pb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12">
        <img
          class="h-32 ml-28 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          titlemenu1="Community"
          :to1="'/communityoverview'"
          titlemenu2="Lost Pet Listing"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <ListingTopBar />
      <ListingPets :data="petsaslost" />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import ListingTopBar from "./ListingTopBar.vue";
import ListingPets from "./ListingPets.vue";

import DataserviceLogo from "../DataserviceLogo.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeaderGlobal,
    ListingTopBar,
    ListingPets,
    DataserviceLogo,
  },
  data() {
    return {
      listLostPet: [],
    };
  },
  computed: {
    ...mapGetters({
      petsaslost: "panicbutton/getPetsaslost",
    }),
  },

  async created() {
   /*  console.log("------------");
    this.getPetsaslost(); */
  },
  methods: {
    ...mapActions({
      getPetsaslost: "panicbutton/getPetsaslost",
    }),
  },
};
</script>

<style></style>
