<template>
  <div class="my-6">
    <ListingSection />
  </div>
</template>

<script>
import ListingSection from "../components/lostpetlisting/ListingSection.vue";

export default {
  components: {
    ListingSection,
  },
};
</script>